import React, { useEffect } from 'react';
import Layout from '../../../components/layout';
import { navigate } from 'gatsby';
// import Vacancy from '../../../components/shared-components/vacancy';

const TechContWriter = () => {
  useEffect(() => {
    navigate('/careers/#vacancy-list');
  }, []);
  
  return (
    <Layout>
      <></>
      {/* <Vacancy
        division="Sales & Marketing"
        vacancy="Content Writer and Editor (English)"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            We are looking for an ambitious Writer to write and edit engaging articles, tutorials, and documentation to
            help grow our automation movement.
          </p>
        }
        jobResponsibilities={
          <>
            <p className="secondary-text mb-0">Working in the sales & marketing team to:</p>
            <ul className="mb-0">
              <li className="secondary-text">Create “how-to” type tutorials</li>
              <li className="secondary-text">Create content and short instructions for our app features</li>
              <li className="secondary-text">
                Researching and writing long-form content about SEO, growth hacking, and online marketing to grow our
                organic traffic.
              </li>
            </ul>
          </>
        }
        qualifications={
          <>
            <ul>
              <li className="secondary-text">Fluent in (US) English</li>
              <li className="secondary-text">Native grammar and writing style</li>
              <li className="secondary-text">2+ years of professional work experience working as a writer </li>
              <li className="secondary-text">
                A passion for copywriting and the ability to write clear and engaging copy
              </li>
              <li className="secondary-text">SEO and WordPress experience is ideal</li>
              <li className="secondary-text">
                Experience writing easy-to-follow tutorials or documentation for a non-technical audience
              </li>
              <li className="secondary-text">
                Able to organize, prioritize and manage multiple projects simultaneously{' '}
              </li>

              <li className="secondary-text">
                Self-starter, always looking for opportunities to improve, grow and share ideas.{' '}
              </li>
            </ul>
            <p className="secondary-text">*Please provide examples of past projects and copies in English.</p>
          </>
        }
      /> */}
    </Layout>
  );
};

export default TechContWriter;
